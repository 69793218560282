var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-icon", on: { click: _vm.click } }, [
    _vm.icon
      ? _c("img", {
          staticClass: "icon",
          attrs: { src: _vm.icon, referrerpolicy: "no-referrer" },
        })
      : _c(
          "svg",
          {
            staticClass: "icon default",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 25 25",
            },
          },
          [
            _c("g", { attrs: { "data-name": "Icon 24 • Contact" } }, [
              _c("path", {
                staticStyle: { fill: "#333", stroke: "#fff", opacity: ".005" },
                attrs: { d: "M0 0h24v24H0z", transform: "translate(.5 .5)" },
              }),
              _vm._v(" "),
              _c(
                "g",
                {
                  staticStyle: {
                    fill: "transparent",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                },
                [
                  _c("path", {
                    staticStyle: { stroke: "none" },
                    attrs: {
                      d: "M317.29 436.954c-1.303-.743-3.279-1.672-5.23-2.46v-1.917c.421-.326 1.094-1.145 1.17-3.076.514-.297.808-1.046.808-1.775 0-.65-.235-1.158-.626-1.423.37-.927.504-1.928.385-2.928l-.003-.032-.008-.032c-.188-.743-.756-1.351-1.643-1.757-.733-.336-1.67-.52-2.637-.52-.968 0-1.905.184-2.638.52-.71.325-1.215.779-1.484 1.328a2.562 2.562 0 0 0-.2-.008c-.653 0-.976.288-1.132.53-.498.768.067 2.025.441 2.857l.005.012c-.486.276-.798.864-.798 1.594 0 .812.387 1.448.97 1.676.09 1.925.821 2.732 1.27 3.048v1.904c-1.951.787-3.926 1.716-5.23 2.459A11.397 11.397 0 0 1 297.5 429c0-6.341 5.159-11.5 11.5-11.5s11.5 5.159 11.5 11.5c0 2.988-1.137 5.798-3.21 7.954z",
                      transform: "translate(-296.5 -416.5)",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticStyle: { stroke: "none" },
                    attrs: {
                      d: "M317.19 436.326A10.892 10.892 0 0 0 320 429c0-6.065-4.935-11-11-11s-11 4.935-11 11c0 2.73.992 5.303 2.81 7.326 1.235-.676 2.929-1.47 4.63-2.167v-1.335c-.499-.444-1.108-1.313-1.25-2.959-.605-.372-.99-1.101-.99-1.998 0-.732.256-1.352.679-1.757-.394-.908-.814-2.101-.247-2.978.177-.274.6-.728 1.464-.757.34-.524.868-.957 1.564-1.276.797-.365 1.807-.566 2.846-.566 1.038 0 2.048.201 2.845.566 1.03.472 1.693 1.194 1.92 2.09.01.042.018.084.023.127.114.964.012 1.93-.295 2.84.34.376.54.922.54 1.57 0 .505-.12 1.01-.335 1.421a2.04 2.04 0 0 1-.487.621c-.114 1.697-.68 2.585-1.157 3.04v1.35c1.701.699 3.395 1.492 4.63 2.168m.182 1.255c-1.391-.836-3.734-1.925-5.812-2.747V432.3s1.178-.443 1.178-3.166c.896 0 1.23-2.534-.01-2.534a5.498 5.498 0 0 0 .573-3.166c-.64-2.534-6.95-2.534-7.59 0-2.689-.545-.55 2.801-.55 3.166-1.281 0-1.281 2.534 0 2.534 0 2.723 1.279 3.166 1.279 3.166v2.534c-2.078.822-4.42 1.91-5.812 2.747A11.96 11.96 0 0 1 297 429c0-6.617 5.383-12 12-12s12 5.383 12 12c0 3.362-1.394 6.4-3.628 8.58z",
                      transform: "translate(-296.5 -416.5)",
                    },
                  }),
                ]
              ),
            ]),
          ]
        ),
    _vm._v(" "),
    _vm.editable && _vm.trashable
      ? _c(
          "svg",
          {
            staticClass: "trash",
            attrs: {
              "data-name": "Icon 24 • Trash",
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 20 20",
            },
            on: { click: _vm.trash },
          },
          [
            _c("path", {
              staticStyle: { fill: "#fff" },
              attrs: {
                d: "M14.113 18.333H4.222a1.941 1.941 0 0 1-1.937-1.94V5.405H.417a.417.417 0 0 1 0-.835h1.105v-.35a1.941 1.941 0 0 1 1.94-1.938h3.047a2.719 2.719 0 0 1 .9-1.626 2.681 2.681 0 0 1 3.517 0 2.72 2.72 0 0 1 .9 1.626h3.05a1.94 1.94 0 0 1 1.937 1.938v.35h1.105a.417.417 0 0 1 0 .835h-1.867v10.988a1.941 1.941 0 0 1-1.938 1.94zm-1.521-11a.313.313 0 0 0-.313.313v7.228a.313.313 0 0 0 .626 0v-7.23a.313.313 0 0 0-.312-.313zm-3.424 0a.312.312 0 0 0-.312.313v7.228a.313.313 0 0 0 .625 0v-7.23a.313.313 0 0 0-.312-.313zm-3.424 0a.312.312 0 0 0-.312.313v7.228a.311.311 0 1 0 .622 0v-7.23a.311.311 0 0 0-.309-.313zM3.462 3.114a1.106 1.106 0 0 0-1.1 1.105v.351h.3a.063.063 0 0 0 .019 0h.02a.044.044 0 0 1 .02 0 .105.105 0 0 0 .019 0H15.6a.056.056 0 0 0 .021 0h.017a.044.044 0 0 1 .02 0 .105.105 0 0 0 .019 0h.308v-.35a1.107 1.107 0 0 0-1.105-1.105zM9.169.832a1.859 1.859 0 0 0-1.816 1.45h3.63A1.853 1.853 0 0 0 9.169.832z",
                transform: "translate(.833 .834)",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.editable
      ? _c(
          "div",
          {
            staticClass: "edit",
            class: { default: !_vm.icon },
            on: { click: _vm.edit },
          },
          [
            _c("div", { staticClass: "button-row" }, [
              _vm._v("\n      Edit\n    "),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }